import { Card, Chip, FormControl, MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import Header from './BasicHeader';
import Footer from './Footer';
import Layout from './Layout';
import { colors } from './styles';

const cardSize = 290;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      maxWidth: 1140,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    sourceImage: {
      width: 30,
      height: 30,
      borderRadius: 15,
      marginRight: theme.spacing(2),
    },
    chip: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    card: {
      width: cardSize,
      height: cardSize / 1.8,
      margin: theme.spacing(1),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      justifyContent: 'center',
    },
    cardMediaContainer: {
      flex: 1,
      backgroundColor: 'rgba(0,0,0, 0.8)',
      display: 'flex',
      overflow: 'hidden',
      justifyContent: 'center',
    },
    cardMedia: {
      minWidth: '100%',
      minHeight: '100%',
      objectFit: 'cover',
      width: 'auto',
      height: 'auto',
    },
    badgesContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    badgeContainer: {
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      height: 24,
      borderRadius: 12,
      color: 'white',
    },
    cardContent: {
      color: 'white',
      backgroundColor: 'rgba(0,0,0, 0.8)',
      width: '100%',
      padding: theme.spacing(1),
    },
    sectionHeader: {
      marginTop: theme.spacing(2),
      margin: theme.spacing(1),
      width: '100%',
    },
  })
);

enum TrackType {
  Comp = 'competitions',
  Membership = 'memberships',
  Other = 'others',
  Plan = 'plans',
}

const TrackMap = {
  [TrackType.Comp]: { color: colors.leaderboardColor, name: 'Competitions' },
  [TrackType.Membership]: {
    color: colors.membershipColor,
    name: 'Weekly Programming',
  },
  [TrackType.Other]: {
    color: colors.primaryColor,
    name: 'Camps, Live Coaching and Others',
  },
  [TrackType.Plan]: { color: colors.planColor, name: 'Training Plans' },
};

function maybeSortTracks(tracks: any[], trackType: TrackType) {
  if (tracks && trackType === TrackType.Comp) {
    return sortBy(tracks, (t) => t.compDate);
  } else {
    return tracks;
  }
}

const DiscoverPage = ({ pageContext }) => {
  const { sources } = pageContext;
  const [sourceSlug, setSourceSlug] = useQueryParam('source', StringParam);
  const [ergType, setErgType] = useQueryParam('erg', StringParam);
  const styles = useStyles();
  const [filteredTracks, setFilteredTracks] = useState(pageContext.tracks);
  const [selectedSource, setSelectedSource] = useState<any>();

  useEffect(() => {
    const tracks = pageContext.tracks
      .filter((t) =>
        sourceSlug && sourceSlug !== 'All' ? t.source.slug === sourceSlug : true
      )
      .filter((t) => (ergType ? t.features.includes(ergType) : true));
    if (sourceSlug === 'erg-engine-system') {
      setSourceSlug('wattpower', 'replaceIn');
    } else if (sourceSlug && tracks.length === 0) {
      setSourceSlug(undefined, 'replaceIn');
    } else {
      setFilteredTracks(tracks);
      setSelectedSource(sources.find((s) => s.slug === sourceSlug));
    }
  }, [sourceSlug, ergType]);

  const groupedTracks = groupBy(filteredTracks, (track) => {
    if (track.planWeeks) return TrackType.Plan;
    if (
      track.activePrices.length &&
      track.activePrices.every((p) => p.recurringMonths)
    )
      return TrackType.Membership;
    if (track.compStatus) return TrackType.Comp;
    return TrackType.Other;
  });

  return (
    <Layout titlePrefixes={[selectedSource ? selectedSource.name : 'Discover']}>
      <Header />
      <section>
        <Box className={styles.container}>
          <div className="section-heading text-center">
            <Link to="/discover">
              <Typography variant="h4" component="h2">
                Discover
              </Typography>
            </Link>

            <FormControl
              variant="outlined"
              size="small"
              className={styles.formControl}
            >
              <Select
                value={sourceSlug || 'All'}
                onChange={(event) =>
                  setSourceSlug(event.target.value as string)
                }
              >
                <MenuItem value="All">
                  <em>All Coaches</em>
                </MenuItem>
                {sources.map((source) => (
                  <MenuItem key={source.slug} value={source.slug}>
                    <img className={styles.sourceImage} src={source.imageUrl} />
                    {source.name}
                  </MenuItem>
                ))}
              </Select>
              <Box>
                <Chip
                  className={styles.chip}
                  label="Row"
                  color={ergType === 'row' ? 'primary' : undefined}
                  variant={ergType === 'row' ? 'default' : 'outlined'}
                  onClick={() =>
                    setErgType((type) => (type === 'row' ? undefined : 'row'))
                  }
                />
                <Chip
                  className={styles.chip}
                  label="Ski"
                  color={ergType === 'ski' ? 'primary' : undefined}
                  variant={ergType === 'ski' ? 'default' : 'outlined'}
                  onClick={() =>
                    setErgType((type) => (type === 'ski' ? undefined : 'ski'))
                  }
                />
                <Chip
                  className={styles.chip}
                  label="Bike"
                  color={ergType === 'bike' ? 'primary' : undefined}
                  variant={ergType === 'bike' ? 'default' : 'outlined'}
                  onClick={() =>
                    setErgType((type) => (type === 'bike' ? undefined : 'bike'))
                  }
                />
                <Chip
                  className={styles.chip}
                  label="Fan Bike"
                  color={ergType === 'fan-bike' ? 'primary' : undefined}
                  variant={ergType === 'fan-bike' ? 'default' : 'outlined'}
                  onClick={() =>
                    setErgType((type) =>
                      type === 'fan-bike' ? undefined : 'fan-bike'
                    )
                  }
                />
                <Chip
                  className={styles.chip}
                  label="MultiErg"
                  color={ergType === 'multi' ? 'primary' : undefined}
                  variant={ergType === 'multi' ? 'default' : 'outlined'}
                  onClick={() =>
                    setErgType((type) =>
                      type === 'multi' ? undefined : 'multi'
                    )
                  }
                />
              </Box>
            </FormControl>
            {(Object.keys(TrackMap) as TrackType[]).map((trackType) => {
              const tracks = maybeSortTracks(
                groupedTracks[trackType],
                trackType
              );

              if (!tracks) return null;

              return (
                <Box
                  key={trackType}
                  id={trackType}
                  flexWrap="wrap"
                  flexDirection="row"
                  display="flex"
                  alignItems="center"
                  className={styles.content}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ color: TrackMap[trackType].color }}
                    className={styles.sectionHeader}
                  >
                    {TrackMap[trackType].name}
                  </Typography>
                  {tracks.map((track) => (
                    <Link key={track.id} to={`/${track.webPath}`}>
                      <Card className={styles.card}>
                        <Box className={styles.cardMediaContainer}>
                          <img
                            className={styles.cardMedia}
                            src={track.featuredImage || track.imageUrl}
                            alt={track.name}
                          />
                        </Box>
                        <Box className={styles.cardContent}>
                          <Typography
                            variant="caption"
                            component="p"
                            align="left"
                          >
                            {track.source.name}
                          </Typography>
                          <Typography
                            style={{ lineHeight: 1.3 }}
                            variant="subtitle1"
                            component="h3"
                            align="left"
                          >
                            {track.name}
                          </Typography>
                          <Box className={styles.badgesContainer}>
                            <Typography
                              className={styles.badgeContainer}
                              style={{
                                backgroundColor: TrackMap[trackType].color,
                              }}
                            >
                              {track.activePrice.displayText}
                            </Typography>
                            {!!track.compDateDisplay && (
                              <Typography
                                className={styles.badgeContainer}
                                style={{
                                  backgroundColor: TrackMap[trackType].color,
                                }}
                              >
                                {track.compDateDisplay}
                              </Typography>
                            )}
                            {track.planWeeks && (
                              <Typography
                                className={styles.badgeContainer}
                                style={{
                                  backgroundColor: TrackMap[trackType].color,
                                }}
                              >
                                {track.planWeeks} weeks
                              </Typography>
                            )}
                            {track.features
                              .filter(
                                (f) =>
                                  f === 'row' ||
                                  f === 'ski' ||
                                  f === 'bike' ||
                                  f === 'fan-bike' ||
                                  f === 'multi'
                              )
                              .map((f) => (
                                <Typography
                                  key={f}
                                  className={styles.badgeContainer}
                                  style={{
                                    backgroundColor: TrackMap[trackType].color,
                                  }}
                                >
                                  {f}
                                </Typography>
                              ))}
                          </Box>
                        </Box>
                      </Card>
                    </Link>
                  ))}
                </Box>
              );
            })}
          </div>
        </Box>
      </section>
      <Footer />
    </Layout>
  );
};

export default DiscoverPage;
